@tailwind base;
@tailwind components;
@tailwind utilities;
.about {
    margin: 0 auto;
    max-width: 800px;
    padding: 70px;
    font-size: 18px;
    line-height: 1.6;
    color: #c4c4c4;
    font-family: Arial, sans-serif;
    text-shadow:
            1px 1px 1px #000000,
            2px 2px 2px #000000,
            3px 3px 3px #000000
}

.App {
    text-align: center;
}

.App-logo {
    height: 15vmin;
    pointer-events: none;
}


.App-header {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.baseline-shadow {
    color: white;
    text-shadow:
            1px 1px 1px #000000,
            2px 2px 2px #000000,
            3px 3px 3px #000000
}

.blur-shadow {
    color: black;
    text-shadow: 2px 2px 4px #000000;
    filter: blur(2px);
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

#tsparticles {
    position: fixed;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    left: 0;
    top: 0;
    z-index: -1;
}
